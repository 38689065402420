// @ts-nocheck
/*
 *  This file auth-service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { EMPTY, forkJoin, Observable, of, Subject } from "rxjs";
import { catchError, concatMap, first, map, switchMap, tap } from "rxjs/operators";
import { ServerErrorHandler } from "./error-handler/server-error-handler.service";
import { ConfigurationService } from "./configuration.service";
import { EnterpriseWallService } from "src/app/modules/enterprise-wall/enterprise-wall.service";
import { RequestMenuModel } from "../models/request-menu.model";
import { menuNavigationModel } from "../constants/routing-constant";
import { IUserLicenseInfo, SharedService } from "./shared.service";
import { CommonDropdownService } from "./dropdowns/common-dropdown.service";
import { LocationDropdownsService } from "./dropdowns/location-dropdowns.service";
import { OsDropdownsService } from "./dropdowns/os-dropdowns.service";
import { UserRightsService } from "./user-rights.service";
import { HttpService } from "./http-service";
import { LogonUserDetailModel, TLoginTokenResponse } from "../models/common.models";
import * as moment from "moment";
import { IHroneGenericResponse } from "src/app/modules/enterprise-wall/social-enterpise-wall/social-wall.model";
import { SingleSignonService } from "src/app/authentication/single-signon.service";
import { SnackbarAlertService } from "./snackbar-alert.service";
import { TCoreMenuAccessTeamActionsForPin } from "src/app/main/sidebar/sidebar.model";
import { TGlobalStateProcessVersion } from "src/app/store/global/global.reducer";
import { EGlobalStateProcessId, EGlobalStateStoreNames, GLOBAL_API_URLS, TCoreMenuAccessPayload, TCoreMenuAccessResponse, TPageAccessRightStoreData, TWorkforceSetupSettingResponse } from "src/app/store/global/global.constants";
import { refreshPageAccessRightSuccess } from "src/app/store/global/global.action";


@Injectable()
export class AuthService {
  onloggedInEvent = new EventEmitter();
  onCloseDialogEvent = new EventEmitter();
  otpResendEvent = new Subject()
  onLogout$ = new Subject();

  base_auth_url;
  refresh_token;
  domainCode;
  base_url;
  employeeInfoAfterLogin = null;
  superUserInfoAfterLogin = null;
  newAddedMenu: TCoreMenuAccessResponse[] = [];
  pageName = '';
  currentOption: { Id: number, detail: string, type: string, isUsed: boolean };
  onNextClicked: boolean;

  constructor(
    private _httpClient: HttpClient,
    public _router: Router,
    private _serverError: ServerErrorHandler,
    public _config: ConfigurationService,
    public _wall: EnterpriseWallService,
    private _shared: SharedService,
    private http: HttpService,
    public _common: CommonDropdownService,
    public _location: LocationDropdownsService,
    public _osdd: OsDropdownsService,
    public _userRights: UserRightsService,
    public _singleSignon: SingleSignonService,
    private _snackBar: SnackbarAlertService
  ) {
    this.domainCode = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : _config.domainCode;
    this.base_url = _config["base_url"];
    this.base_auth_url = _config["base_auth_url"];
    this.newAddedMenu = localStorage.getItem('newAddedMenu') ? JSON.parse(localStorage.getItem('newAddedMenu')) : localStorage.getItem('newAddedMneu') ? JSON.parse(localStorage.getItem('newAddedMneu')) : [];
  }

  getHeaders() {
    const authlogin = JSON.parse(localStorage.getItem("authlogin"));
    const accessToken = authlogin ? authlogin.access_token : "";
    return new HttpHeaders({
      "Content-Type": "application/json",
      domainCode: this.domainCode,
      Authorization: "Bearer " + accessToken,
      AccessMode: 'W'
    });
  }

  getLoginHeaders(extra = false) {
    const authlogin = JSON.parse(localStorage.getItem("authlogin"));
    const accessToken = authlogin ? authlogin.access_token : "";
    return !extra ?
      new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        domainCode: this.domainCode,
        Authorization: "Bearer " + accessToken,
        AccessMode: 'W'
      }) :
      new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        domainCode: this.domainCode,
        Authorization: "Bearer " + accessToken,
        AccessMode: 'W',
        'hrone-refresh-header': 'true'
      })
  }

  public Get<T = any>(url: string): Observable<T> {
    return this._httpClient
      .get<T>(this.base_url + url, { headers: this.getHeaders() })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public Post<T = any, P = any>(url: string, param: P): Observable<T> {
    return this._httpClient
      .post<T>(this.base_url + url, param, { headers: this.getHeaders() })
      .pipe(catchError(err => this._serverError.handleServerError(err)));
  }

  public PostUnAuthorize(url, param): Observable<any> {
    const headers = this.getHeaders();
    headers.delete("Authorization");
    return this._httpClient
      .post(this.base_auth_url + url, param, { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')));
  }

  public LoginUnAuthorized(url, param, extra = false): Observable<any> {
    const headers = this.getLoginHeaders(extra);
    headers.delete("Authorization");
    return this._httpClient
      .post(this.base_auth_url + url, param, { headers: headers, withCredentials: true })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')));
  }

  encodedPost(url, param) {
    const headers = this.getLoginHeaders();
    return this._httpClient.post(this.base_auth_url + url, param, { headers: headers })
      .pipe(catchError(err => this._serverError.handleServerError(err, 'isUnauthorize')));
  }

  setLoginAuth(data) {
    localStorage.setItem("authlogin", JSON.stringify(data));
    this._shared.getTokenInfo();
  }



  isAuthenticated() {
    if (localStorage.getItem("authlogin")) {
      return true;
    }
    this.http.updateSignalRAuthTokenSubject.next({ token: null, isLogout: true });
    return false;
  }

  isAuthenticatedOrSSO(): boolean {
    const token: TLoginTokenResponse | null = JSON.parse(localStorage.getItem("authlogin"));
    return !!(token && (token?.refresh_token || "").toLowerCase() !== 'na' && !this.onNextClicked);
  }


  isAccessTokenExpired(): boolean {
    if (JSON.parse(localStorage.getItem("isRefreshingToken"))) return false;
    const gmtNow = new Date().toUTCString();
    const token: TLoginTokenResponse | null = JSON.parse(localStorage.getItem("authlogin"));
    if ((token?.refresh_token || "").toLowerCase() === 'na') return false;
    const howManyUnitsAheadFromExpiry = moment(token['.expires']).diff(moment(gmtNow), 'seconds');
    return token === null || howManyUnitsAheadFromExpiry <= token.expires_in / 2;
  }


  logout() {
    const isSuperUser = JSON.parse(localStorage.getItem('authlogin'))?.isSuperUser;
    try {
      if (localStorage.getItem('authlogin')) {
        const authlogin = JSON.parse(localStorage.getItem("authlogin"));
        const accessToken = authlogin ? authlogin.access_token : "";
        if (this._shared.supportUserInfo && this._shared.supportUserInfo.supportUserLoginId && this._shared.supportUserInfo.mappedUserId) {
          this.logOutFromSupportUser(accessToken);
        } else {
          this.logOutFromWeb(accessToken);
        }
      }
      this.clearLocalStorage();
      if (isSuperUser) this._router.navigate(['/product-management/login']);
      if (!isSuperUser) this._router.navigate(["login"]);
    } catch (err) { }


    try {
      this._wall.resetAllData();
      this._common.reset();
      this._location.reset();
      this._osdd.reset();
    } catch (err) { }



    this._shared.reset();
    this.onLogout$.next(Math.random());
  }

  clearLocalStorage() {
    localStorage.removeItem('authlogin');
    localStorage.removeItem('newAddedMneu');
    localStorage.removeItem('rawMenusWithActions');
    localStorage.removeItem('socketId');
    localStorage.removeItem('rawMenus');
    localStorage.removeItem('logOnUserDetails');
    localStorage.removeItem('whiteLabels');
    localStorage.removeItem('uid');
    localStorage.removeItem('GC_Accesstoken');
    localStorage.removeItem('requestSource');
    localStorage.removeItem('selectedHRDeskSearch');
    if (this.employeeInfoAfterLogin?.employeeStatusId == 7) {
      localStorage.removeItem('recent_enterprise_search');
    }
    localStorage.removeItem('samlSessionIndex');
    localStorage.removeItem('pinnedMenusList');
    localStorage.removeItem('recentParentMenusList');
    localStorage.removeItem('contactDialogShown');
    localStorage.removeItem('newAddedMenu');
    // please change the same function in hrone-network service as well.
  }

  logOutFromWeb(accessToken) {
    if (accessToken) {
      try {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          domainCode: this.domainCode,
          Authorization: "Bearer " + accessToken,
          AccessMode: 'W'
        });

        this._httpClient
          .get(this.base_url + "/api/LogOnUser/LogOff", {
            headers: headers
          })
          .subscribe(res => {
            localStorage.removeItem('launchDialog');
          });
      } catch (err) { }
    }
  }



  getLoginInfo(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.Get('/api/LogOnUser/LogOnUserDetail').subscribe(res => {
        if (res) {
          if (res.isLoggedOn < 2) this.updateLogOnStatus();
          this.employeeInfoAfterLogin = res;
          localStorage.setItem('logOnUserDetails', JSON.stringify(res));
          localStorage.setItem('isEmailBlocked', JSON.stringify(res?.isEmailBlocked));
          this._shared.logOnUserDetails = res;

          if (this._shared.logOnUserDetails && this._shared.logOnUserDetails.identifier) {
            this._shared.logOnUserIdentifiers = this._shared.logOnUserDetails.identifier;
          }
          this.getOnboardingStatus().subscribe({
            next: settingRes => {
              if (settingRes?.status === 'Y') {
                this.isProcessStart().subscribe({
                  next: onboardingRes => {
                    this._shared.onboardingAccess = true;
                    if (!onboardingRes.isProcessStart) {
                      this.getOtherInfo().then((res: boolean) => {
                        this._shared.userLoggedOut.next(res);
                        resolve(res);
                      }).catch((e) => {
                        reject(false)
                      })
                      return
                    }
                    const pageNumber: number = onboardingRes?.selectedOnPageNumber ? onboardingRes?.selectedOnPageNumber : 1;
                    localStorage.setItem('isProcessStart', JSON.stringify(true))
                    localStorage.setItem('onboardingPageInfo', JSON.stringify({ segmentTypeId: onboardingRes?.selectedSegmentTypeId, pageNumber }))
                    if (onboardingRes?.selectedSegmentTypeId) this._router.navigate(['/onboarding-config', pageNumber, onboardingRes?.selectedSegmentTypeId]);
                    else this._router.navigate(['/onboarding-config', pageNumber])
                    this.getOtherInfo().then(() => {
                      resolve(false);
                    }).catch((e) => {
                      reject(false)
                    }
                    );
                  },
                  error: (e) => {
                    this.getOtherInfo().then((res: boolean) => {
                      this._shared.userLoggedOut.next(res);
                      resolve(res);
                    }).catch((e) => {
                      reject(false);
                    }
                    )
                  }
                })
              } else {
                this.getOtherInfo().then((res: boolean) => {
                  this._shared.userLoggedOut.next(res);
                  resolve(res);
                }).catch((e) => {
                  reject(false)
                }
                );
              }
            },
            error: () => {
              this.getOtherInfo().then((res: boolean) => {
                this._shared.userLoggedOut.next(res);
                resolve(res);
              }).catch((e) => {
                reject(false);
              }
              )
            }
          })
        } else {

          resolve(false);

        }
      }, (e) => {

        reject(false);
      }
      )
    });
  }

  getOnboardingStatus(): Observable<TWorkforceSetupSettingResponse> {
    return this._shared._globalState.getVersionDetails().pipe(
      switchMap((data) => {
        this._shared._globalState.handleVersionDetails(data);
        return this._shared._globalState.getWorkforceSetupSetting(68);
      }),
      catchError(() => this._shared._globalState.getWorkforceSetupSetting(68))
    );
  }

  isProcessStart() {
    const onboardingURL = '/api/core/onboard/CheckProcess';
    return this.http.Get(onboardingURL)
  }

  updateLogOnStatus() {
    const url = '/api/workforce/Employee/UpdateLogOnStatus';
    this.http.Put(url, {}).subscribe(res => res)
  }
  getOtherInfo(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const MenuModelObject: TCoreMenuAccessPayload = {
        menuType: 'P',
        parentKey: 9999,
        menuId: 0,
        includeMenu: true
      };
      const MenuModelObject2: TCoreMenuAccessPayload = {
        menuType: 'A',
        parentKey: 9999,
        menuId: 0,
        includeMenu: true
      };

      const menuAction$: Observable<{
        menuAction: TCoreMenuAccessTeamActionsForPin;
        menuList: TCoreMenuAccessResponse[];
        menuAccess: TCoreMenuAccessResponse[];
      }> = forkJoin({
        menuAction: this.Get<TCoreMenuAccessTeamActionsForPin>(GLOBAL_API_URLS[EGlobalStateProcessId.PageAccessRights].MENU_ACCESS),
        menuList: this.Post<TCoreMenuAccessResponse[], TCoreMenuAccessPayload>(GLOBAL_API_URLS[EGlobalStateProcessId.PageAccessRights].MENU_ACCESS, MenuModelObject),
        menuAccess: this.Post<TCoreMenuAccessResponse[], TCoreMenuAccessPayload>(GLOBAL_API_URLS[EGlobalStateProcessId.PageAccessRights].MENU_ACCESS, MenuModelObject2)
      }).pipe(
        map((data) => {
          this._shared._globalState.store.dispatch(
            refreshPageAccessRightSuccess({
              data: {
                menuAccess: data.menuAccess,
                menuAction: data.menuAction,
                menuList: data.menuList,
                refresh: false
              },
              domainCode: this._shared._globalState.logOnUserDetails().domainCode,
              employeeId: this._shared._globalState.logOnUserDetails().employeeId,
              storename: EGlobalStateStoreNames.PageAccessRights,
              versionId: this._shared._globalState.versionhash.get(EGlobalStateProcessId.PageAccessRights) ?? '1',
            })
          );
          return (data ? structuredClone(data) : data) as {
            menuAction: TCoreMenuAccessTeamActionsForPin;
            menuList: TCoreMenuAccessResponse[];
            menuAccess: TCoreMenuAccessResponse[];
          }
        }),
        catchError(() => {

          return EMPTY;
        })
      );

      const forkedMenus:
        Observable<{
          menuAction: TCoreMenuAccessTeamActionsForPin;
          menuList: TCoreMenuAccessResponse[];
          menuAccess: TCoreMenuAccessResponse[];
        }>
        =
        this._shared._globalState.indexdb.getByKey<TGlobalStateProcessVersion<{
          data: TPageAccessRightStoreData,
          loading: boolean,
        }>>('hronestore', EGlobalStateProcessId.PageAccessRights).pipe(
          map((dbData) => ({
            expired: dbData && 'data' in dbData && dbData.data.menuAccess && this._shared._globalState.shouldGetFromCache(
              dbData.versionId,
              EGlobalStateProcessId.PageAccessRights,
              dbData.employeeId,
              dbData.domainCode,
              dbData.date
            ),
            data: dbData
          })),
          concatMap((cacheExpired) => {
            const dbData = cacheExpired.data;
            if (dbData && dbData.data.menuAccess && dbData.data.menuAction && dbData.data.menuList &&
              this._shared._globalState.shouldGetFromCache(
                dbData.versionId,
                EGlobalStateProcessId.PageAccessRights,
                dbData.employeeId,
                dbData.domainCode,
                dbData.date
              )) {
              return of({
                menuAccess: dbData.data.menuAccess,
                menuAction: dbData.data.menuAction,
                menuList: dbData.data.menuList
              }) as
                Observable<{
                  menuAction: TCoreMenuAccessTeamActionsForPin;
                  menuList: TCoreMenuAccessResponse[];
                  menuAccess: TCoreMenuAccessResponse[];
                }>
            }
            return menuAction$;
          }),
          catchError(() => {

            return menuAction$;
          })
        )


      const userLicenceInfo = this.Get<IUserLicenseInfo>('/api/UserLicense/info');
      const pinnedMenus = this.Get('/api/core/MenuAccess/LeftPin').pipe(catchError(err => of([])));

      forkJoin({
        forkedMenus,
        userLicenceInfo,
        pinnedMenus
      }).pipe(
        first(),
        tap((data) => {
          const action = data.forkedMenus.menuAction;
          if (action && action.menuPermissionRights && action.menuPermissionRights.length) {
            action.menuPermissionRights = action.menuPermissionRights.map(menu => {
              menu.navigationCode = (menu.navigationCode || '').toLowerCase();
              return menu;
            });
            localStorage.setItem('rawMenusWithActions', JSON.stringify(action.menuPermissionRights));
            this._shared.rawMenusWithActions = action.menuPermissionRights;
            this._userRights.rawMenusWithActions = action.menuPermissionRights;
          }

          let menuList = data.forkedMenus.menuList;
          if (menuList && menuList.length) {
            menuList = menuList.map(menu => {
              menu.navigationCode = (menu.navigationCode || '').toLowerCase();
              menu['pinType'] = ['1', '2', '3', '7'].includes(menu.menuId.toString()) ? 'fixed' : 'unpin';
              menu['isMenuVisibleWithinL0'] = ['1', '2', '3'].includes(menu.menuId.toString()) ? 'true' : menu.menuId == 7 ? '' : 'false';
              return menu;
            });
            const rawMenus = menuList;
            try {
              const found = rawMenus.find(e => e.menuId === 64);
              if (found) {
                found.sequenceNumber = 6;
              }
            } catch (e) {

            }
            this._shared.bindNewBetaOnMenu(menuList);
            const rawMenusNew = RequestMenuModel.concat(rawMenus as unknown as typeof RequestMenuModel);
            menuNavigationModel.forEach(item => {
              try {
                const itemFound = rawMenusNew.find(e => e.navigationCode === item.code);
                if (itemFound) {
                  itemFound.navigationPath = item.path;
                }
              } catch (e) {

              }
            });
            localStorage.setItem('rawMenus', JSON.stringify(rawMenusNew));
            this._shared.rawMenus = rawMenusNew;
            this._shared.refreshRecentSearchedProfileData();
          }

          if (data.forkedMenus.menuAccess.length) {
            data.forkedMenus.menuAccess = data.forkedMenus.menuAccess.map(menu => {
              menu.navigationCode = (menu.navigationCode || '').toLowerCase();
              return menu;
            });
            this.newAddedMenu = data.forkedMenus.menuAccess;
            localStorage.setItem('newAddedMenu', JSON.stringify(data.forkedMenus.menuAccess));
          }

          if (data.userLicenceInfo) {
            this._shared.userLicenseInfo = data.userLicenceInfo;
          }

          let pinnedMenusList = data.pinnedMenus.map(menu => {
            menu.navigationCode = (menu.navigationCode || '').toLowerCase();
            menu['pinType'] = 'pinned';
            menu['isMenuVisibleWithinL0'] = 'true';
            menu['iconName'] = this._shared.getModuleIconNames(menu.moduleId);
            return menu;
          });

          if (!pinnedMenusList.length && data.forkedMenus.menuList.length) {
            const filteredMenus = data.forkedMenus.menuList.filter(menu => ['6', '5', '4'].includes(menu.menuId.toString()));
            pinnedMenusList = filteredMenus.map(filteredMenu => {
              filteredMenu.navigationCode = (filteredMenu.navigationCode || '').toLowerCase();
              filteredMenu['pinType'] = 'pinnedauto';
              filteredMenu['isMenuVisibleWithinL0'] = 'true';
              return filteredMenu;
            });;
          }



          menuNavigationModel.forEach(item => {
            try {
              const itemFound = pinnedMenusList.find(e => e.navigationCode === item.code);
              if (itemFound) {
                itemFound.navigationPath = item.path;
              }
            } catch (e) {

            }
          });
          localStorage.setItem('pinnedMenusList', JSON.stringify(pinnedMenusList));
          this._shared.pinnedMenus = pinnedMenusList;

          resolve(true);
        }),
        catchError(() => {

          resolve(false);
          return EMPTY;

        })
      ).toPromise().then(() => { }).catch((e) => {

        reject(false);
      });
    });
  }



  logOutFromSupportUser(accessToken: string) {
    if (accessToken) {
      try {
        const headers = new HttpHeaders({
          "Content-Type": "application/json",
          domainCode: this.domainCode ?? "",
          Authorization: "Bearer " + accessToken,
          AccessMode: 'W'
        });
        const supportUser = encodeURIComponent(this._shared.supportUserInfo.supportUserLoginId);
        const mappedUser = encodeURIComponent(this._shared.supportUserInfo.mappedUserId);
        this._httpClient
          .post(this.base_url + `/api/support/logOff?supportUserLoginId=${supportUser}&domainCode=${this._shared.supportUserInfo.domainCode}&mappedUserId=${mappedUser}`, '', {
            headers: headers
          })
          .subscribe(() => {
            this._shared.supportUserInfo = null;
          });
      } catch (err) {

      }
    }
  }

  getRefreshToken(extra = false): Observable<TLoginTokenResponse> {
    const authLoginStr = localStorage.getItem("authlogin");
    const logOnUserDetailsStr = localStorage.getItem('logOnUserDetails');
    const authlogin = JSON.parse(authLoginStr ?? JSON.stringify({}));
    const url = "/oauth2/token";
    const logOnUserDetails = JSON.parse(logOnUserDetailsStr ?? JSON.stringify({}));
    const username = localStorage.getItem('logOnUserName') ? localStorage.getItem('logOnUserName') : logOnUserDetails ? logOnUserDetails.officialEmail : '';
    const domainCode = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode;
    const body = `refreshId=${authlogin ? authlogin.refresh_token : ''}&companyDomainCode=${domainCode ?? ''}&grant_type=refresh_token&username=${username || ''}`;
    return this.LoginUnAuthorized(url, body, extra);
  }
  callsAfterLogin(): void {
    const url = '/api/LogOnUser/AuditTrail';
    const reqData = {
      "logOnSource": "W",
      "internetProtocolAddress": '',
      "remarks": ""
    };
    const newBetaJSON = this._httpClient.get('/assets/json/new-beta.json');
    const dashboardTaxRegime = this.Get('/api/core/Dashboard/Taxregime/show').pipe(catchError(err => of(null)));
    const commonLoginEvent = this.Get('/api/engage/employee/CommonLoginEvent/' + this._shared.logOnUserDetails.employeeId).pipe(catchError(err => of([])));
    const sendIpAddress = this.Post(url, reqData).pipe(catchError(err => of(null)));
    const whiteLabels = this.Get('/api/core/WhiteLabeling').pipe(catchError(err => of([])));
    forkJoin([newBetaJSON, dashboardTaxRegime, commonLoginEvent, sendIpAddress, whiteLabels]).pipe(first(), tap(data => {

      this._shared.betaValue = data[0];
      if (data[1]) {
        if (data[1].financialYear) {
          this._shared.dashboardRegimeRes.financialYear = data[1].financialYear;
        }
        if (data[1].showTaxRegime) {
          this._shared.dashboardRegimeRes.showTaxRegime = true;
        }
        if (data[1].taxRegime) {
          this._shared.dashboardRegimeRes.taxRegime = data[1].taxRegime;
        }
        if (data[1].considerConfirmInvestment) {
          this._shared.dashboardRegimeRes.considerConfirmInvestment = true;
        }
      }
      if (data[2] && data[2].length) {
        this._shared.commonLoginPopupData = data[2];
        this._shared.pulseFeedbackArr = data[2].filter(e => e.serviceEventId === 2);
        this._shared.getPulseData(this._shared.pulseFeedbackArr[0]);
      }
      if (data[4] && data[4].length) {
        localStorage.setItem('whiteLabels', JSON.stringify(data[4]));
        data[4].forEach(ele => {
          const prop = (ele.originalDisplayName)?.toLowerCase();
          this._shared.whiteLabels[prop] = ele.displayName;
        });
      }

    })).subscribe();
  }

  logOutFromSingleSignOn(logOnTypeDetails: any, logOnUserDetails: LogonUserDetailModel, samlSessionIndex: string): void {
    if (logOnTypeDetails && logOnTypeDetails.isSingleSignOnEnable && logOnTypeDetails.isActiveUser && logOnTypeDetails.logOnSetting) {
      if (logOnTypeDetails.logOnSetting.logOnType == 5) {
        if (logOnTypeDetails.logOnSetting.subLogOnType == 3) {
          const header = new HttpHeaders({ 'Content-Type': 'application/json' });
          this._httpClient.delete(logOnTypeDetails.logOnSetting.logOffPath, { headers: header, withCredentials: true }).subscribe();
        } else {
          if (samlSessionIndex) {
            const headers = new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              AccessMode: 'W',
              domainCode: localStorage.getItem('domainCode') ?? "",
            });
            const body = new URLSearchParams({
              logOnUserId: logOnUserDetails.employeeId.toString(),
              domainCode: logOnUserDetails.domainCode,
              sessionIndex: samlSessionIndex
            }).toString();
            this._httpClient.post<IHroneGenericResponse>(this.http._config.base_auth_url + '/api/saml/request/logout', body, { headers })
              .subscribe((res: IHroneGenericResponse) => {
                if (res?.validationType == '0') {
                  this._singleSignon.onSignIn(res.messageCode);
                }
              });
          }
        }
      }
    }
  }

  logInSamlO365(username: string): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      AccessMode: 'W',
      domainCode: localStorage.getItem('domainCode') ?? "",
    });
    const body = new URLSearchParams({
      logOnParam: username
    }).toString();
    this._httpClient.post<IHroneGenericResponse>(this.http._config.base_auth_url + '/api/saml/request/login', body, { headers }).subscribe(
      (res: IHroneGenericResponse) => {
        if (res?.validationType == '0') {
          this._singleSignon.onSignIn(res.messageCode);
        } else {
          this._snackBar.Alert(res.message, res.validationType);
        }
      });
  }

  redirectAfterToken(redirect_url: string, target: '_self' | '_blank'): void {
    this.getRefreshToken().subscribe({
      next: (auth) => {
        const url = this.generateRedirectURL(redirect_url,auth);
        this.setLoginAuth(auth);
        window.open(url, target);
        return;
      },
      error: () => {
        localStorage.setItem('redirect_url', redirect_url);
        this.logout();
      }
    });
  }

  generateRedirectURL(redirect_url: string, auth: TLoginTokenResponse): string {
    const url = `${redirect_url}?access_token=${auth.access_token}&auth=${btoa(this._config.base_auth_url)}`;
    return url;
  }
}
