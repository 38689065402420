/*
 *  This file global.effect.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  loadBadgeFeedSuccess,
  loadGlboalStateFromStorage,
  updateGlboalStateFromStorage,
  emptyaction,
  refreshPageAccessRightSuccess,
  refreshPageAccessRightStart,
  enterpriseEnableDisableSuccess,
  subscribeModuleSuccess,
  workforceSettingSuccess,
  validateFeatureSuccess,
  employeecontestEnableDisableSuccess,
  loadFeedCountSuccess
} from './global.action';
import { TGlobalState, TGlobalStateProcessVersion } from './global.reducer';
import { Store } from '@ngrx/store';
import { selectGlobalState } from './global.selector';
import { EGlobalStateProcessId, EGlobalStateStoreNames, TPageAccessRightStoreData } from './global.constants';
import { ServerErrorHandler } from 'src/app/global/services/error-handler/server-error-handler.service';
import { EMPTY, Observable, of } from 'rxjs';
import { HroneGlobalStateService } from './global-state.service';

@Injectable()
export class HroneGlobalStateEffects {

  constructor(
    private actions$: Actions,
    private store :Store<TGlobalState>,
    private _serverErrorHandler:ServerErrorHandler,
    private _globalState:HroneGlobalStateService,
  ) { }











  setGlboalStateFromStorage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(loadGlboalStateFromStorage),
      concatLatestFrom(() => this.store.select<TGlobalState>(selectGlobalState)),
      switchMap(() => {

        return this._globalState.getVersionDetails().pipe(
          concatMap((apiResponse) => {
            this._globalState.handleVersionDetails(apiResponse);
            return this._globalState.getAllEntries('hronestore').pipe(
              map((state) => {
                return updateGlboalStateFromStorage({ state });
              })
            );
          })
        );
      })
    ) }
  );

  setGlobalStateToStorage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(loadBadgeFeedSuccess,
        refreshPageAccessRightSuccess,
        loadFeedCountSuccess,
        enterpriseEnableDisableSuccess,
        employeecontestEnableDisableSuccess,
        subscribeModuleSuccess,
        workforceSettingSuccess,
        validateFeatureSuccess,
      ),
      concatLatestFrom(() => this.store.select<TGlobalState>(selectGlobalState)),
      mergeMap(([action, globalState]) => {
        const storename: keyof typeof EGlobalStateStoreNames = action.storename as keyof typeof EGlobalStateStoreNames;


          const storeData = (globalState as unknown as {hroneGlobal:TGlobalState}).hroneGlobal[storename as keyof TGlobalState];



          return this.addOrUpdate('hronestore',storeData.processId, storeData).pipe(
            map(() => emptyaction()),
            catchError(() => {
              throw EMPTY;
            })
          );




      })
    ) }
  );

  pageaccessright$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshPageAccessRightStart),
      switchMap(() =>
        this._globalState.getEntryByStoreNameAndKey<
          TGlobalStateProcessVersion<{
            data: TPageAccessRightStoreData;
            loading: boolean;
          }>
        >('hronestore', EGlobalStateProcessId.PageAccessRights).pipe(
          switchMap((dbData) => {
            if (
              dbData &&
              'data' in dbData &&
              this._globalState.shouldGetFromCache(
                dbData.versionId,
                EGlobalStateProcessId.PageAccessRights,
                dbData.employeeId,
                dbData.domainCode,
                dbData.date
              )
            ) {
              return of(dbData.data).pipe(
                map(() => {
                  return emptyaction();
                })
              );
            } else {
              return this._serverErrorHandler.onRefreshLogon().pipe(
                map(() => {
                    this._serverErrorHandler.refreshMenus();
                  return refreshPageAccessRightSuccess({
                    data: {
                      menuAccess: null,
                      menuAction: null,
                      menuList: null,
                      refresh: false,
                    },
                    domainCode: this._globalState.logOnUserDetails().domainCode,
                    employeeId: this._globalState.logOnUserDetails().employeeId,
                    storename: EGlobalStateStoreNames.PageAccessRights,
                    versionId:
                      this._globalState.versionhash.get(
                        EGlobalStateProcessId.PageAccessRights
                      ) ?? '1',
                  });
                })
              );
            }
          }),
          catchError(() =>
            this._serverErrorHandler.onRefreshLogon().pipe(
              map((response: boolean) => {
                if (response) {
                  this._serverErrorHandler.refreshMenus();
                }
                return refreshPageAccessRightSuccess({
                  data: {
                    menuAccess: null,
                    menuAction: null,
                    menuList: null,
                    refresh: false,
                  },
                  domainCode: this._globalState.logOnUserDetails().domainCode,
                  employeeId: this._globalState.logOnUserDetails().employeeId,
                  storename: EGlobalStateStoreNames.PageAccessRights,
                  versionId:
                    this._globalState.versionhash.get(
                      EGlobalStateProcessId.PageAccessRights
                    ) ?? '1',
                });
              }),
              tap(() => {
                this._globalState
                  .getVersionDetails()
                  .subscribe({
                    next: (data) => this._globalState.handleVersionDetails(data),
                  });
              })
            )
          )
        )
      ),
      tap(() => {
        this._globalState
          .getVersionDetails()
          .subscribe({
            next: (data) => this._globalState.handleVersionDetails(data),
          });
      })
    );
  });



  addOrUpdate<T>(storeName: 'hronestore', key: number, data: T): Observable<T> {
    return this._globalState.getEntryByStoreNameAndKey<T>(storeName, key).pipe(
      switchMap((existingData) => {
          return existingData ? this._globalState.updateEntry<T>(storeName, {...data,date:new Date()}): this._globalState.addEntry(storeName, {...data,date:new Date()});
      }),
      catchError(() => {
        console.error(`could not get ${storeName} to add or update ${key}`);
        throw EMPTY;
      })
    );
  }

}


