/*
 *  This file enterprise-wall.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { PaginationModel } from "src/app/global/models/common.models";

export enum WISHES_ID {
  birthday = 1,
  joining_anniv = 2,
  wedding_anniv = 3,
  new_joining = 7
}

export type WISH_TYPE = 1 | 2 | 3;

export const defaultBadgeImg = '/assets/images/default-badge.png';

export const URLS = {
  attendance: '/api/timeoffice/attendance/Daywise/',
  wishes: '/api/core/Dashboard/Wishes/All/',
  wishAction: '/api/core/Dashboard/Wishes',
  perWishes: '/api/core/Dashboard/Wishes/User/',
  wishedMe: '/api/core/Dashboard/Personal/Wishes',
  taskCount: '/api/core/Dashboard/InboxSummary',
  notiCount: '/api/core/Dashboard/Notification/count',
  notification: '/api/core/Dashboard/DashboardAlert/',
  clearNoti: '/api/core/Dashboard/DashboardAlert',
  widgets: '/api/core/Dashboard/Widgets',
  badgesDd: '/api/Engage/Badge/Setting/1/false',
  badgeList: `/api/Engage/Badge/Setting/ForAll/1`,
  badgeSubmit: '/api/Engage/Badge/BadgesForEmployee',
  badgeLimit: '/api/Engage/Badge/BadgeLimit/',
  feeds: '/api/Engage/Badge/Feed',
  feedCount: '/api/Engage/Badge/FeedCount',
  cheer: '/api/Engage/Badge/Cheers',
  pointsSubmit: '/api/Engage/RewardPoints/EmployeeRewardPoints',
  pointLimit: '/api/Engage/RewardPoints/GivenPoints/',
  profile: '/api/core/Dashboard/Profile/Completion',
  scheduleOne: '/api/pms/oneonone/request',
  initiative: '/api/pms/initiative/Request',
  hrPost: '/api/Engage/HRPost/HRPost',
  cheeredBy: '/api/Engage/Badge/FeedCheersDetails?feedId=',
  deleteFeed: '/api/Engage/Badge/Inactive/Feed/',
  leaveWidget: '/api/shortcut/widget/LeaveDetails',
  shortcutWidget: '/api/shortcut/widget',
  deleteShortcut: '/api/shortcut/widget/',
  leaveBalaceWidget: '/api/timeoffice/Employee/leaveBalance/EnterpriseWall',
  jobOpening: `/api/recruitment/position/JobOpeningForEmployeeProfile`,
  plannedLeaveCount : `/api/timeoffice/Leave/Planned/Count/Publicaly`,
  leaveWidgetPlanned : `/api/timeoffice/Leave/Planned/Publicaly`

};


export class LeaveWidgetModel {
  constructor(
    public fromDate: string = '',
    public toDate: string = '',
    public searchValue: string = '',
    public pagination: PaginationModel = new PaginationModel(1, 10),
  ) { }
}


