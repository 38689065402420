// @ts-nocheck
/*
 *  This file file-upload.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { SnackbarAlertService } from './snackbar-alert.service';
import { contentTypes } from '../models/hrone-file.model';
import { TFileDownloadVirtualUrl } from '../models/common.models';
import { EMPTY, Observable } from 'rxjs';
import { FileUploadWithFolderIdPayload, FileUploadWithFolderIdResponse } from '../components/file/file-attacher/file-attacher.component';
import { SharedService } from './shared.service';


const mime = require('mime/lite');

export type UploadFileApiType = 'file' | 'userFile' | 'image' | 'compressImage';

export interface UploadFileModel {
    apiType?: UploadFileApiType;
    File: File;
    FolderName?: string;  // if apiType == file || apiType ==  image || apiType ==  compressImage
    FileExtensionsAllow?: number;   // if apiType == file || apiType ==  userFile
    EmployeeCode?: string;    // if apiType == userFile
    ModuleName?: string;       // if apiType == userFile
    ProcessName?: string;     // if apiType == userFile
    CompressRatio?: number;   // if  apiType ==  compressImage
    FileMaxSize?: string;
    FolderId?: number;
    isResize?: boolean;
}

export interface FileResponseModel {
    fileName: string;
    fileDbName: string;
    resizedImageDbFileName: string;
    folderName: string;
}

export const fileUrls = {
    fileVirtualUrl: '/api/core/File/FileVirtualUrl?fileDbName=',
    fileUpload: '/api/core/File/Upload',
    fileUploadUser: '/api/core/File/UploadUserFile',
    imageResizeUpload: '/api/core/file/UploadResizeImage',
    download: '/api/core/file/Download?fileDbName=',
    compressImage: '/api/core/file/UploadCustomImage',
    trimedFileVirtualUrl: '/api/core/file/UrlPathEncode/FileVirtualUrl',
    externalfileVirtualUrl: '/api/external/access/FileVirtualUrl?fileDbName=',
    externalfileUploadUser: '/api/external/access/UploadUserFile',
    fileUploadUrl: '/api/File/UploadUserFile'

};

export type FileType = 'image' | 'video' | 'audio' | 'file';

@Injectable()
export class FileUploadService {
    private fileTypeIcons = {
        'pdf': 'fa-solid fa-file-pdf errorIcon',
        'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-solid fa-file-excel primaryTxt',
        'vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-solid fa-file-word primaryTxt',
        'zip': 'fa-solid fa-file-zip primaryTxt',
        'default': 'fa-solid fa-file'
      };
    fileSize = '5MB';

    fileValidation = {
        0: {                  // for resize image upload
            size: 5242880,
            types: ['png', 'jpg', 'jpeg', 'bmp'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are jpeg, jpg, png, bmp'
        },
        1: {
            size: 5242880,
            types: ['jpeg', 'jpg', 'png', 'bmp'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are jpeg, jpg, png, bmp'
        },
        2: {
            size: 5242880,
            types: ['pdf', 'doc', 'docx', 'txt'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, txt'
        },
        3: {
            size: 5242880,
            types: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, xls, xlsx, png'
        },
        4: {
            size: 5242880,
            types: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'bmp', 'zip', 'rar'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, xls, xlsx, jpeg, jpg, png, bmp, zip, rar'
        },
        5: {
            size: 5242880,
            types: ['xlsx'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File format allowed is xlsx '
        },
        6: {
            size: 5242880,
            types: ['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'bmp'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, txt, xls, xlsx, jpeg, jpg, png, bmp'
        },
        7: {
            size: 5242880,
            types: ['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'bmp'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, jpeg, jpg, png, bmp '
        },
        8: {
            size: 5242880,
            types: ['pdf'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File format allowed is  pdf '
        },
        9: {
            size: 5242880,
            types: ['doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'bmp'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are doc, docx, xls, xlsx, jpeg, jpg, png, bmp '
        },
        10: {
            size: 5242880,
            types: ['pdf', 'doc', 'docx', 'xls', 'xlsx'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, xls, xlsx '
        },
        11: {
            size: 5242880,
            types: ['xls', 'xlsx'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are xls, xlsx'
        },
        12: {
            size: 5242880,
            types: ['doc', 'docx'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are doc, docx'
        },
        13: {
            size: 5242880,
            types: ['doc', 'docx', 'txt', 'rtf', 'pdf', 'html', 'htm'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed are doc, docx, txt, rtf, pdf, html, htm'
        },

        14: {
            size: 5242880,
            types: ['zip'],
            sizeMessage: 'Maximum file allowed size is 5MB',
            typeMessage: 'File formats allowed  zip'
        },
        15: {
            size: 20971520,
            types: ['zip'],
            sizeMessage: 'Maximum file allowed size is 20MB',
            typeMessage: 'File formats allowed  zip'
        },
        16: {
            size: 20971520,
            types: ['pem', 'cert'],
            sizeMessage: 'Maximum file allowed size is 20MB',
            typeMessage: 'File formats allowed  pem , cert'
        },
        17: {
            size: 10485760,
            types: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'bmp', 'zip', 'rar'],
            sizeMessage: 'Maximum file allowed size is 10MB',
            typeMessage: 'File formats allowed are pdf, doc, docx, xls, xlsx, jpeg, jpg, png, bmp, zip, rar'
        }
    };

    constructor(
        private _httpService: HttpService,
        public _snackBar: SnackbarAlertService,
        private _shared:SharedService,
    ) { }

    // getFileConfig() {
    //     this._httpService.Get('assets/json/config.json').subscribe(response => {
    //         const fileSizeInByte = response['file_size_in_mb'] * 1048576;
    //         if (fileSizeInByte) {
    //             this.fileSize = this.convertByteTo(fileSizeInByte);
    //             Object.keys(this.fileValidation).forEach(element => {
    //                 this.fileValidation[element].size = fileSizeInByte;
    //                 this.fileValidation[element].sizeMessage = 'Maximum file allowed size is ' + this.fileSize;
    //             });
    //         }
    //     });
    // }

    convertByteTo(bytes) {
        if (bytes === 0) {
            return '0 B';
        }
        const k = 1000,
            dm = 3,
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    }

    getFileValidation(file, key) {
        const fileSize = this.fileValidation[key].size;
        const fileTypes = this.fileValidation[key].types;

        // check extention
        const ext = this.getFileExtention(file.name).toLowerCase();
        if (fileTypes.indexOf(ext) < 0) {
            this._snackBar.Alert(this.fileValidation[key].typeMessage, '3');
            return false;
        }
        // end ext

        // size validation
        if (file.size > fileSize) {
            this._snackBar.Alert(this.fileValidation[key].sizeMessage, '3');
            return false;
        }
        // end size

        return true;

    }

    getFileTypeValidation(file, key) {
        const fileTypes = this.fileValidation[key].types;

        // check extention
        const ext = this.getFileExtention(file.name).toLowerCase();
        if (fileTypes.indexOf(ext) < 0) {
            this._snackBar.Alert(this.fileValidation[key].typeMessage, '3');
            return false;
        }
        // end ext

        return true;

    }

    getFileExtention(name) {
        try {
            if (name) {
                return name.split('.').pop().toLowerCase();
            }
        } catch (err) {

        }

        return name;
    }

    uploadFileWithFolderId(payload: FileUploadWithFolderIdPayload, ignoreSize = false,uploadForEmployeeCode:string|null = null): Observable<FileUploadWithFolderIdResponse> {

        const flag = ignoreSize
            ? this.getFileTypeValidation(payload.File, payload.FileExtensionsAllow)
            : this.getFileValidation(payload.File, payload.FileExtensionsAllow);
        if (!flag) return EMPTY;
        const formData = new FormData();
        formData.append('File', payload.File);
        formData.append('FolderId', payload.FolderId.toString());
        formData.append('EmployeeCode', uploadForEmployeeCode ?? this._shared.logOnUserDetails.employeeCode);
        return this._httpService.PostFileUpload(fileUrls.fileUploadUrl, formData);
    }

    uploadFile<T = any>(payload, ignoreSize?): Promise<T> {
        return new Promise((resolve, reject) => {
            if (payload?.FolderId) {
                let flag;
                const fileExtensionKey = payload.FileExtensionsAllow ? payload.FileExtensionsAllow : 0
                if (ignoreSize) {
                    flag = this.getFileTypeValidation(payload.File, fileExtensionKey);
                } else {
                    flag = this.getFileValidation(payload.File, fileExtensionKey);
                }
                if (flag) {
                    const formData = new FormData();
                    formData.append('File', payload.File);
                    if (payload.FolderId === 33) {
                        formData.append('emp_folder', 'true');
                        formData.append('random_folder', 'true');
                        formData.append('folder_name', 'request/resume');
                    } else {
                        formData.append('EmployeeCode', payload.EmployeeCode);
                    }
                    formData.append('FolderId', payload.FolderId);

                    this._httpService.PostFileUpload(fileUrls.fileUploadUrl, formData)
                        .subscribe(
                            (response) => {
                                resolve(response);
                            },
                            err => {
                                reject(new Error("Whoops server error!"));
                            });
                } else {
                    reject(new Error("Whoops client error!"));
                }
            } else {
                const fileExtensionKey = payload.FileExtensionsAllow ? payload.FileExtensionsAllow : 0;
                let flag;
                if (ignoreSize) {
                    flag = this.getFileTypeValidation(payload.File, fileExtensionKey);
                } else {
                    flag = this.getFileValidation(payload.File, fileExtensionKey);
                }

                if (flag) {

                    const formData = new FormData();
                    formData.append('File', payload.File);
                    formData.append('FolderName', payload.FolderName);
                    let url = fileUrls.imageResizeUpload;
                    if (payload.apiType === 'compressImage') {
                        url = fileUrls.compressImage;
                        formData.append('CompressionRatio', payload.CompressRatio ? String(payload.CompressRatio) : '50');
                    } else if (payload.apiType === 'file') {
                        url = fileUrls.fileUpload;
                        formData.append('FileExtensionsAllow', String(payload.FileExtensionsAllow));
                        if (payload.FileMaxSize) {
                            formData.append('FileMaxSize', payload.FileMaxSize);
                        }
                    } else if (payload.apiType === 'userFile') {
                        url = fileUrls.fileUploadUser;
                        formData.append('FileExtensionsAllow', String(payload.FileExtensionsAllow));
                        formData.append('ModuleName', payload.ModuleName ? payload.ModuleName : '');
                        formData.append('ProcessName', payload.ProcessName ? payload.ProcessName : '');
                        formData.append('EmployeeCode', payload.EmployeeCode ? payload.EmployeeCode : '');
                    }

                    this._httpService.PostFile(url, formData)
                        .subscribe(
                            (response) => {
                                resolve(response);
                            },
                            err => {
                                reject(err?.error ?? "Whoops client error!");
                            });
                } else {
                    reject(new Error("Whoops client error!"));
                }
            }
        });
    }

    uploadExternalFile(payload: UploadFileModel, appId, domainCode, ignoreSize?): Promise<any> {
        console.log('payload', payload);
        return new Promise((resolve, reject) => {
            const fileExtensionKey = payload.FileExtensionsAllow ? payload.FileExtensionsAllow : 0;
            let flag;
            if (ignoreSize) {
                flag = this.getFileTypeValidation(payload.File, fileExtensionKey);
            } else {
                flag = this.getFileValidation(payload.File, fileExtensionKey);
            }

            if (flag) {
                const formData = new FormData();
                formData.append('File', payload.File);
                formData.append('FolderName', payload.FolderName);
                let url = fileUrls.imageResizeUpload;
                if (payload.apiType === 'file') {
                    url = '/api/external/access/File/Upload';
                    formData.append('FileExtensionsAllow', String(payload.FileExtensionsAllow));
                } else if (payload.apiType === 'userFile') {
                    url = '/api/external/access/UploadUserFile';
                    formData.append('FileExtensionsAllow', String(payload.FileExtensionsAllow));
                    formData.append('ModuleName', payload.ModuleName ? payload.ModuleName : '');
                    formData.append('ProcessName', payload.ProcessName ? payload.ProcessName : '');
                    formData.append('EmployeeCode', payload.EmployeeCode ? payload.EmployeeCode : '');
                } else if (payload.apiType === 'image') {
                    url = '/api/external/access/File/Upload';
                    formData.append('FileExtensionsAllow', String(1));
                    formData.append('IsResize', String(payload.isResize));
                }

                this._httpService.PostExternalFile(url, appId, domainCode, formData)
                    .subscribe(
                        (response) => {
                            resolve(response);
                        },
                        err => {
                            reject(new Error("Whoops server error!"));
                        });
            } else {
                reject(new Error("Whoops client error!"));
            }
        });
    }

    getFileVirtualPath(fileDbName, externalCondition?: { 'external': boolean, 'appId': string, 'domainCode': string }): Promise<string> {
        return new Promise((resolve, reject) => {
            if (fileDbName) {
                const query = encodeURIComponent(fileDbName);
                if (externalCondition?.external) {
                    this.useExternalUrl(query, resolve, reject, externalCondition);
                } else {
                    this.useInternalUrl(query, resolve, reject);
                }
            } else {
                reject(new Error("Whoops client error!"));
            }
        });
    }

    private useExternalUrl(query: string, resolve: (value: string | PromiseLike<string>) => void, reject: (reason?: any) => void, externalCondition: { 'external': boolean, 'appId': string, 'domainCode': string }) {
        const url = fileUrls.externalfileVirtualUrl + query + "&requestSource=null";
        this._httpService.GetUnauthorizedWithAppId(url, externalCondition.appId, externalCondition.domainCode).subscribe((resp: TFileDownloadVirtualUrl) => {
            if (resp && resp.fileVirtualPath) {
                resolve(resp.fileVirtualPath);
            } else {
                reject(new Error("Whoops server error!"));
            }
        }, err => {
            reject(new Error("Whoops client error!"));
        })
    }

    private useInternalUrl(query: string, resolve: (value: string | PromiseLike<string>) => void, reject: (reason?: any) => void) {
        const url = fileUrls.fileVirtualUrl + query + "&requestSource=null";
        this._httpService.Get(url).subscribe(
            (resp: TFileDownloadVirtualUrl) => {
                if (resp && resp.fileVirtualPath) {
                    resolve(resp.fileVirtualPath);
                } else {
                    reject(new Error("Whoops server error!"));
                }
            }, err => {
                reject(new Error("Whoops client error!"));
            });
    }

    getTrimedFileVirtualPath(fileDbName): Promise<any> {
        return new Promise((resolve, reject) => {
            if (fileDbName) {
                const url = fileUrls.trimedFileVirtualUrl + `?fileDbName=${fileDbName}` + "&requestSource=null";
                this._httpService.Get(url).subscribe(
                    resp => {
                        if (resp && resp.fileVirtualPath) {
                            resolve(resp.fileVirtualPath);
                        } else {
                            reject(new Error("Whoops server error!"));
                        }
                    }, err => {
                        reject(new Error("Whoops client error!"));
                    });
            } else {
                reject(new Error("Whoops client error!"));
            }
        });
    }

    getBlob(base64String, fileName) {
        const contentType = this.getContentType(fileName);
        const blob = this.b64toBlob(base64String, contentType);
        return blob;
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    getContentType(fileName) {
        const ext = this.getFileExtention(fileName);
        if (contentTypes[ext]) {
            return contentTypes[ext];
        }
        return "text/plain";
    }

    isImage(fileName) {
        const ext = this.getFileExtention(fileName);
        const list = this.fileValidation['0'].types;
        if (list.indexOf(ext) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    isFileViewable(fileName) {
        const ext = this.getFileExtention(fileName);
        const list = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];
        if (list.indexOf(ext) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    downloadFile(path) {
        if (path) {
            fetch(path).then((response) => {
                return response.blob()
            }).then(blob => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobURL;
                const index = path.indexOf('?');
                const arr = path.slice(0, index).split('/');
                a.download = arr[arr.length - 1];
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(blobURL);
            });
        }
    }

    getFileType(fileName) {
        let val: FileType = 'file';
        const type = mime.getType(fileName);
        if (type) {
            const t = type.split('/')[0];
            if (t == 'image') {
                val = 'image';
            } else if (t == 'video') {
                val = 'video';
            } else if (t == 'audio') {
                val = 'audio';
            }
        }

        console.log(fileName, type);

        return val;
    }
      getFileIconClass(fileType: string): string {
        const extension = fileType.split('/')[1];
        return this.fileTypeIcons[extension] || this.fileTypeIcons['default'];
    }
}
